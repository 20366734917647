
import { defineComponent } from "vue";
import SubCatalogueGrid from "@/components/controls/catalogueGrid/SubCatalogueGrid.vue";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import rolePolicyCreateEditDialog from "@/components/catalogues/RoleCatalogue/RolePolicyCreateEditDialog.vue";
import { BooleanColumnConfig } from "@/components/controls/catalogueGrid/columns/booleanColumnConfig";

export default defineComponent({
  components: {
    SubCatalogueGrid,
  },
  props: {
    parentModel: { required: true },
  },
  methods: {},
  computed: {
    rolePolicyCreateEditDialog() {
      return rolePolicyCreateEditDialog;
    },
  },
  data() {
    return {
      columns: [
        new TextColumnConfig({
          dataField: "policyDescription",
          caption: "Наименование",
          readOnly: true,
        }),
        new BooleanColumnConfig({
          dataField: "use",
          caption: "Включена",
        }),
      ],
      selectionConfig: new TableSelectionConfig({
        mode: "single",
        hoverStateEnabled: false,
      }),
      designConfig: new TableDesignConfig({
        height: "400px",
        horizontalScroll: false,
      }),
      editingConfig: new TableEditingConfig({
        allowUpdating: true,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: false,
        allowDataFill: false,
        allowExport: false,
        allowClearFilter: false,
        allowDeleteMultipleRows: false,
        mode: "cell",
      }),
    };
  },
});
